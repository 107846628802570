<script>

export default {
  name: 'App',
  components: {}
}
</script>
<template>
  <div class="App">
    <div class="bashka">
      <img src="../public/img/logo.svg" alt="logo">
      <div class="subdiv">
        <p class="main_title">Nizhegorodispytaniya Testing Centre</p>
      </div>
    </div>

    <div class="second_div">
      <div class="text_block">
        <p id="f">The testing centre was accredited as a testing laboratory in the national accreditation system in 2015
          <a href="https://pub.fsa.gov.ru/ral/view/3350/applicant">(Unique number of accreditation record in the registry of accredited entities РОСС RU.0001.21АЮ49)</a>. </p>
        <p id="s">It was appointed as a base testing centre in the framework of implementation of the national project
          “International Cooperation and Export” in 2019.</p>
        <p id="th">It became entitled to issue test reports using ILAC MRA combined mark in 2023. This mark is an official
          symbol of the International Laboratory Accreditation Cooperation (ILAC). Results of studies conducted in the
          testing centre are recognized internationally other organizations that signed ILAC Mutual Recognition
          Agreement, i.e. in more than 100 countries worldwide.</p>
        <img class="mob_ilac" src="../public/img/ilac-ru%20(1)%201.png" alt="ilac">

        <p id="fth">In the same year, the Federal Budgetary Institution “State Regional Centre for Standardization, Metrology and
          Tests in Nizhny Novgorod Region” was the first organization of Rosstandart system appointed a technical
          service in accordance with UNECE Regulation No. 43 concerning tests of glazing materials for vehicles.
          Following tests, manufacturers receive a communication on type approval from Rosstandart that is a document
          allowing for certification of products without any barriers and additional tests. A communication on type
          approval is recognized as an official document by all state parties to 1958 Geneva Agreement.</p>
        <p id="fith">Nizhegorodispytaniya Testing Centre is included in the National Part of the Unified Register of Conformity
          Assessment Bodies of the Eurasian Economic Union (EAEU) with respect to 28 Technical Regulations of the EAEU
          Customs Union.
        </p>
        <p id="sxth">Nizhegorodispytaniya Testing Centre has nine laboratories equipped with advanced precision measuring
          instruments meeting international standards and providing for high-quality tests.</p>

      </div>
      <div class="img_block">
        <img class="ilac" src="../public/img/ilac-ru%20(1)%201.png" alt="ilac">
        <img class="mashina" src="../public/img/bibika.png" alt="mashina">
        <img class="mashina" src="../public/img/stanok.png" alt="mashina">
        <img class="mashina" src="../public/img/pharaoh.png" alt="mashina">
      </div>
    </div>
    <div class="third_div">
<img src="../public/img/shkaf229.png">
    </div>
    <div class="fourth_div">
      <p>Implementation of advanced analytical technologies and analysis methods allows to carry out the following tests using the facilities of the accredited testing centre:</p>
    </div>
    <div class="fiveth_div">
      <img src="../public/img/infograph.png">
    </div>
    <div class="fourth_div">
      <p>The testing centre performs over 10,000 various product tests annually.
        More detailed information on the testing centre can be obtained from our website</p>
      <a href="https://nncsm.ru/">www.nncsm.ru</a>
    </div>
    <div class="costumers_title"><p>Our customers</p></div>
    <div class="costumer_logo">
      <img src="../public/img/logo_group.png">
    </div>
    <div class="costumers_title"><p>Contact details:</p></div>
    <div class="contacts">
      <div class="person">
        <img src="../public/img/director.png">
        <p>Head of Federal budget institution State regional center of standardization, metrology and testing in Nizhny Novgorod Region<br>
          <b>Alexander Medvedev</b><br>
          Medvedev.A@nncsm.ru<br>
          +7 (910) 137-33-03</p>
      </div>
      <div class="person">
        <img src="../public/img/dolgov.png">
        <p>Head of Customer Service Department<br>
          <b>Aleksei Dolgov</b><br>
          Dolgov@nncsm.ru<br>
          8-800-200-22-14 (ext. 726)<br>
          +7 (930) 070-44-05</p>
      </div>
      <div class="person">
        <img src="../public/img/shigontsev.png">
        <p>Head of the Nizhegorodispytaniya Testing Centre<br>
          <b>Mikhail Shigontsev</b><br>
          Shigontsev@nncsm.ru  ic@nncsm.ru<br>
          8-800-200-22-14 (ext. 120)<br>
          +7 (905) 191-10-99</p>
      </div>

    </div>
    <div>
      <div class="main">
        <div><img src="../public/img/nncsmtittle.svg" alt="nscmtittle"></div>
        <div class="contact_container">
          <div class="phone_div">
            <img src="../public/img/phone_ring_black.svg">
            <p>8 800 200-22-14</p>
          </div>
          <div class="mail_div">
            <a></a><img src="../public/img/heroicons-outline_mail.svg">
            <p>mail@nncsm.ru</p>
          </div>
        </div>
        <div class="image_box">
         <a target="_blank" href="https://t.me/nncsm"><img src="../public/img/logos_telegram.svg"></a>
         <a target="_blank" href="https://vk.com/nncsm"><img src="../public/img/vk_logo.svg"></a>
        </div>
      </div>
    </div>

  </div>
</template>


<style>
html{
  display: flex;
  justify-content: center;
}
a{
  color: rgba(0, 68, 91, 0.61);
}

.person p{
  margin-top: 15px;
  width: 300px;
  font-size: 24px;
  text-align: center;
}
.person{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 401px;
  align-items: center;
  text-align: center;
}
.contacts{
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.costumers_title{
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
}
.costumer_logo{
  margin-top: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fiveth_div{
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fiveth_div img{
  width: 75vw;
}
.fourth_div a{
  font-size: 48px;
}
.fourth_div p{
  margin-top: 50px;
  width: 956px;
}
.fourth_div{
  text-align: center;
  font-size: 32px;
  color: rgba(0, 68, 91, 0.61);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 202px;
}
.third_div{
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.third_div img{
  margin-top: 100px;
  width: 75vw;
}
.mashina{
}
.ilac{

}
.img_block{
  width: 43%;
  margin-left: 75px;
  margin-right: 56px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.text_block p{
  font-family: Circe, sans-serif;
  margin-top: 24px;
  margin-left: 56px;
}
.text_block{
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 28px;
  color: rgba(0, 68, 91, 0.61);
  width: 57%;
  height: 92.9%;
}

.bashka {
  font-family: Circe-bold,sans-serif;
  font-weight: 900;
  height: 477px;
  background-image: url("../public/img/head.png");
  background-size: cover;
}

.bashka img {
  width: 350px;
  margin-left: 60px;
  margin-top: 60px;
}

.main_title {
  position: absolute;
  margin-top: 77px;
  font-size: 64px;
  color: white;
}

.subdiv {
  display: flex;
  justify-content: center;
  width: 100%;
}

.second_div {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  background-size: cover;
}

@media  (min-width: 601px){
  .main{display: none}
  .mob_ilac{
    display: none;
  }

}
@media (max-width: 600px) {

  .contact_div{
    display: none;
  }
  .main{
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact_container{
    justify-content: space-between;
    width: 73.3vw;
    display: flex;
  }
  .phone_div{
    align-items: center;
    justify-content: center;
    font-size: 14px;
    display: flex;

  }
  .phone_div img{
    margin-top: -3px;
    width: 20px;
    height: 14px;
  }
  .phone_div p{
    margin-left: 5.6px;
  }
  .mail_div{
    align-items: center;
    display: flex;
  }
  .mail_div img{
    width: 20px;
    height: 14px;
  }
  .image_box{
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 11vw;
  }
  .mob_ilac{
    display: none;
    //width: 180px;
  }
  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    display: block;
  }
  /* Логотип и заголовок */
  .bashka img {
    width: 50%;
    margin-left: 5px;
    margin-right: auto;
    display: block;
    margin-top: 0px;
  }

  .main_title {
    color: white;
    font-size: 25px;
    margin-top: 50px;
    text-align: center;
    width: 90%;
  }

  /* Блоки с текстом */
  .text_block {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 1.5;
  }
  .text_block p{
    margin-left: 0;
  }

  /* Блоки с изображениями */
  .img_block {
   display: none;
  }

  /* Изображения внутри блоков */
  .img_block img {
    width: 20vw;
    height: auto;
    margin-top: 20px;
  }

  /* Другие блоки */
  .fourth_div, .fiveth_div, .third_div {
    width: 90%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
  }

  .fourth_div p, .fourth_div a, .costumers_title, .contacts .person p {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }

  /* Контакты */
  .contacts {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }

  .person {
    width: 100%;
    margin-bottom: 30px;
  }
  .costumer_logo img{
    width: 80%;
  }
  .fourth_div p{
    width: 90%;
  }
  .img_block{
    width: 30%;
  }
  .bashka{
    background-image: url("../public/img/head.png");
    background-size: cover;
    height:200px;
    padding: 10px;
  }
  .person img{
    width: 80%;
  }
  .second_div{
    align-items: flex-start;
    margin-top:20px;
  }
  .costumer_logo{
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
</style>
